<template>
  <div class="auth-wrapper auth-v2 login-wrap">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
       <img src="@/assets/images/logo/hyre-logo.png" alt="">
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
          class="d-none d-lg-flex align-items-center p-5 left-bg"
          lg="6" xl="7"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <svg fill="none"
               height="711"
               style="transform: scaleX(-1);"
               viewBox="0 0 738 711"
               width="738"
               xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M16.5966 337.594C36.0008 59.4181 382.077 -108.525 557.095 80.2892C712.732 248.194 809.859 520.299 670.322 692.964L262.596 692.963C255.836 680.037 249.424 666.887 243.212 653.867C183.708 529.154 -2.80765 615.769 16.5966 337.594Z"
                fill="#DCEEF8"
            />
            <g opacity="0.6">
              <mask
                  id="mask0"
                  height="13"
                  mask-type="alpha"
                  maskUnits="userSpaceOnUse"
                  width="37"
                  x="403"
                  y="464"
              >
                <path
                    d="M439.728 464.308H403.659V476.793H439.728V464.308Z"
                    fill="white"
                />
              </mask>
              <g mask="url(#mask0)">
                <path
                    d="M439.728 464.308H403.659V476.793H439.728V464.308Z"
                    fill="#171822"
                />
                <path
                    d="M439.728 464.308H403.659V471.244H439.728V464.308Z"
                    fill="#171822"
                />
              </g>
            </g>
            <path
                d="M452.213 476.793H392.561V480.955H452.213V476.793Z"
                fill="#171822"
            />
            <mask
                id="mask1"
                height="93"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                width="130"
                x="356"
                y="372"
            >
              <path
                  d="M479.958 372.75H362.042C358.977 372.75 356.493 375.234 356.493 378.299V458.759C356.493 461.823 358.977 464.308 362.042 464.308H479.958C483.022 464.308 485.507 461.823 485.507 458.759V378.299C485.507 375.234 483.022 372.75 479.958 372.75Z"
                  fill="white"
              />
            </mask>
            <g mask="url(#mask1)">
              <path
                  d="M479.958 372.75H362.042C358.977 372.75 356.493 375.234 356.493 378.299V458.759C356.493 461.823 358.977 464.308 362.042 464.308H479.958C483.022 464.308 485.507 461.823 485.507 458.759V378.299C485.507 375.234 483.022 372.75 479.958 372.75Z"
                  fill="#B1C1FF"
              />
              <path
                  d="M486.894 453.21H356.493V465.696H486.894V453.21Z"
                  fill="#4E60A5"
              />
              <path
                  d="M482.732 372.75H364.816C361.752 372.75 359.267 375.234 359.267 378.299V458.759C359.267 461.823 361.752 464.308 364.816 464.308H482.732C485.797 464.308 488.281 461.823 488.281 458.759V378.299C488.281 375.234 485.797 372.75 482.732 372.75Z"
                  fill="#5F6A76"
              />
              <path
                  d="M478.571 378.299H366.203C365.437 378.299 364.816 378.92 364.816 379.686V446.274C364.816 447.04 365.437 447.661 366.203 447.661H478.571C479.337 447.661 479.958 447.04 479.958 446.274V379.686C479.958 378.92 479.337 378.299 478.571 378.299Z"
                  fill="#F4F7FF"
              />
              <path
                  d="M489.668 453.21H359.267V465.696H489.668V453.21Z"
                  fill="#171822"
              />
            </g>
            <path
                d="M235.542 427.781H230.503C229.87 427.781 229.357 428.294 229.357 428.928V436.884C229.357 437.518 229.87 438.031 230.503 438.031H235.542C236.176 438.031 236.689 437.518 236.689 436.884V428.928C236.689 428.294 236.176 427.781 235.542 427.781Z"
                fill="#849DFF"
            />
            <path
                d="M238.475 432.173H227.57C226.937 432.173 226.424 432.687 226.424 433.32V433.956C226.424 434.589 226.937 435.102 227.57 435.102H238.475C239.108 435.102 239.621 434.589 239.621 433.956V433.32C239.621 432.687 239.108 432.173 238.475 432.173Z"
                stroke="black"
                stroke-width="1.32"
            />
            <path
                clip-rule="evenodd"
                d="M221.095 464.231H245.397L251.579 474.645C252.254 476.5 252.458 477.707 252.193 478.265C251.869 478.945 250.614 479.13 249.794 479.464L217.423 479.437C215.358 479.692 214.326 479.301 214.326 478.265C214.326 477.228 216.582 472.551 221.095 464.231Z"
                fill="#77C5FF"
                fill-rule="evenodd"
            />
            <path
                d="M227.873 435.102V451.787C227.873 452.181 227.772 452.567 227.579 452.91L214.577 476.048C213.957 477.152 214.349 478.55 215.453 479.17C215.796 479.363 216.183 479.464 216.576 479.464H250.157C251.423 479.464 252.45 478.438 252.45 477.171C252.45 476.761 252.34 476.359 252.131 476.006L238.511 452.926C238.303 452.573 238.193 452.171 238.193 451.761V435.102"
                stroke="black"
                stroke-width="1.32"
            />
            <path
                d="M230.505 436.846V443.128"
                stroke="white"
                stroke-width="2.64"
            />
            <path
                d="M230.505 448.162V453.094L219.651 472.47"
                stroke="white"
                stroke-width="2.64"
            />
            <path
                d="M156.96 346.381H151.921C151.287 346.381 150.774 346.894 150.774 347.527V355.484C150.774 356.117 151.287 356.631 151.921 356.631H156.96C157.593 356.631 158.106 356.117 158.106 355.484V347.527C158.106 346.894 157.593 346.381 156.96 346.381Z"
                fill="#4361EE"
                fill-opacity="0.5"
            />
            <path
                d="M159.892 350.773H148.988C148.354 350.773 147.841 351.286 147.841 351.92V352.555C147.841 353.189 148.354 353.702 148.988 353.702H159.892C160.525 353.702 161.039 353.189 161.039 352.555V351.92C161.039 351.286 160.525 350.773 159.892 350.773Z"
                stroke="black"
                stroke-width="1.32"
            />
            <path
                clip-rule="evenodd"
                d="M142.512 382.831H166.814L172.997 393.245C173.672 395.1 173.876 396.306 173.611 396.864C173.287 397.545 172.032 397.73 171.211 398.064L138.84 398.037C136.776 398.291 135.744 397.901 135.744 396.864C135.744 395.828 138 391.15 142.512 382.831Z"
                fill="#4361EE"
                fill-rule="evenodd"
            />
            <path
                d="M149.291 353.702V370.387C149.291 370.78 149.19 371.167 148.997 371.51L135.995 394.648C135.375 395.752 135.767 397.15 136.871 397.77C137.214 397.963 137.601 398.064 137.994 398.064H171.574C172.841 398.064 173.867 397.037 173.867 395.771C173.867 395.361 173.757 394.959 173.549 394.606L159.929 371.526C159.721 371.173 159.611 370.771 159.611 370.361V353.702"
                stroke="black"
                stroke-width="1.32"
            />
            <path
                d="M151.922 355.446V361.728"
                stroke="white"
                stroke-width="2.64"
            />
            <path
                d="M151.922 366.762V371.694L141.069 391.069"
                stroke="white"
                stroke-width="2.64"
            />
            <path
                clip-rule="evenodd"
                d="M77.8976 423.391C79.7669 426.458 83.2063 427.347 88.2159 426.061C95.7303 424.132 96.5326 421.462 111.146 422.515C111.797 430.318 109.425 436.025 104.031 439.638C95.9406 445.058 87.6044 441.815 83.6878 439.005C81.0768 437.131 79.1467 433.346 77.8976 427.649V423.391Z"
                fill="#FF4848"
                fill-rule="evenodd"
            />
            <path
                clip-rule="evenodd"
                d="M99.6807 393.387L99.6812 410.254C106.337 412.424 111.146 418.681 111.146 426.061C111.146 435.243 103.703 442.685 94.5216 442.685C85.3404 442.685 77.8976 435.243 77.8976 426.061C77.8976 418.681 82.7071 412.424 89.3629 410.253L89.3624 393.387H99.6807Z"
                fill-rule="evenodd"
                stroke="black"
                stroke-width="1.32"
            />
            
            <path
                clip-rule="evenodd"
                d="M91.1412 384.215H97.8896C98.5228 384.215 99.0361 384.728 99.0361 385.361V397.904C99.0361 398.537 98.5228 399.05 97.8896 399.05H91.1412C90.508 399.05 89.9948 398.537 89.9948 397.904V385.361C89.9948 384.728 90.508 384.215 91.1412 384.215Z"
                fill="#4361EE"
                fill-opacity="0.5"
                fill-rule="evenodd"
            />
            <path
                d="M100.254 389.947H88.7893C87.8395 389.947 87.0696 390.717 87.0696 391.667C87.0696 392.617 87.8395 393.387 88.7893 393.387H100.254C101.204 393.387 101.974 392.617 101.974 391.667C101.974 390.717 101.204 389.947 100.254 389.947Z"
                stroke="black"
                stroke-width="1.32"
            />
            <path
                d="M71.1959 431.794H125.824M125.824 344.63V480.397V344.63Z"
                stroke="black"
                stroke-width="1.32"
            />
            <path
                d="M125.477 434.66C127.06 434.66 128.343 433.377 128.343 431.794C128.343 430.211 127.06 428.928 125.477 428.928C123.894 428.928 122.611 430.211 122.611 431.794C122.611 433.377 123.894 434.66 125.477 434.66Z"
                fill="black"
            />
            <path
                d="M125.477 365.871C127.06 365.871 128.343 364.588 128.343 363.005C128.343 361.422 127.06 360.139 125.477 360.139C123.894 360.139 122.611 361.422 122.611 363.005C122.611 364.588 123.894 365.871 125.477 365.871Z"
                fill="black"
            />
            <path
                d="M125.824 362.492H175.411"
                stroke="black"
                stroke-width="1.32"
            />
            <path
                d="M192.954 443.496H184.983C184.349 443.496 183.836 444.009 183.836 444.642V445.278C183.836 445.911 184.349 446.424 184.983 446.424H192.954C193.587 446.424 194.101 445.911 194.101 445.278V444.642C194.101 444.009 193.587 443.496 192.954 443.496Z"
                stroke="black"
                stroke-width="1.32"
            />
            <path
                clip-rule="evenodd"
                d="M185.302 462.018H192.634V474.971C192.634 476.995 190.993 478.637 188.968 478.637C186.944 478.637 185.302 476.995 185.302 474.971V462.018Z"
                fill="#77C5FF"
                fill-rule="evenodd"
            />
            <path
                d="M187.406 454.73V473.665M187.406 446.424V451.931V446.424Z"
                stroke="white"
                stroke-width="2.64"
            />
            <path
                clip-rule="evenodd"
                d="M185.302 446.424H192.634V474.971C192.634 476.996 190.993 478.637 188.968 478.637C186.944 478.637 185.302 476.996 185.302 474.971V446.424Z"
                fill-rule="evenodd"
                stroke="black"
                stroke-width="1.32"
            />
            <path
                d="M179.756 443.496H171.785C171.152 443.496 170.638 444.009 170.638 444.642V445.278C170.638 445.911 171.152 446.424 171.785 446.424H179.756C180.389 446.424 180.903 445.911 180.903 445.278V444.642C180.903 444.009 180.389 443.496 179.756 443.496Z"
                stroke="black"
                stroke-width="1.32"
            />
            <path
                clip-rule="evenodd"
                d="M172.105 450.761H179.437V474.971C179.437 476.995 177.795 478.637 175.771 478.637C173.746 478.637 172.105 476.995 172.105 474.971V450.761Z"
                fill="#EFA12C"
                fill-rule="evenodd"
            />
            <path
                d="M174.209 454.73V473.665M174.209 446.424V451.931V446.424Z"
                stroke="white"
                stroke-width="2.64"
            />
            <path
                clip-rule="evenodd"
                d="M172.105 446.424H179.437V474.971C179.437 476.996 177.795 478.637 175.771 478.637C173.746 478.637 172.105 476.996 172.105 474.971V446.424Z"
                fill-rule="evenodd"
                stroke="black"
                stroke-width="1.32"
            />
            <path
                d="M166.559 443.496H158.587C157.954 443.496 157.441 444.009 157.441 444.642V445.278C157.441 445.911 157.954 446.424 158.587 446.424H166.559C167.192 446.424 167.705 445.911 167.705 445.278V444.642C167.705 444.009 167.192 443.496 166.559 443.496Z"
                stroke="black"
                stroke-width="1.32"
            />
            <path
                clip-rule="evenodd"
                d="M158.907 457.194H166.239V474.971C166.239 476.996 164.597 478.637 162.573 478.637C160.548 478.637 158.907 476.996 158.907 474.971V457.194Z"
                fill="#4361EE"
                fill-rule="evenodd"
            />
            <path
                d="M161.011 454.73V473.665M161.011 446.424V451.931V446.424Z"
                stroke="white"
                stroke-width="2.64"
            />
            <path
                clip-rule="evenodd"
                d="M158.907 446.424H166.239V474.971C166.239 476.996 164.597 478.637 162.573 478.637C160.548 478.637 158.907 476.996 158.907 474.971V446.424Z"
                fill-rule="evenodd"
                stroke="black"
                stroke-width="1.32"
            />
            <path
                d="M151.576 480.928V455.21H198.5V480.928"
                stroke="#171822"
                stroke-width="3.96"
            />
            <path
                d="M151.575 470.584H198.499"
                stroke="#171822"
                stroke-width="3.96"
            />
            <path
                clip-rule="evenodd"
                d="M14.8409 454.313H49.8034C50.5299 464.816 48.6081 472.114 44.038 476.209C39.4679 480.303 33.425 481.395 25.9093 479.486C21.0947 476.959 18.0463 474.186 16.7642 471.166C15.482 468.147 14.8409 464.501 14.8409 460.231V454.313Z"
                fill="#EFA12C"
                fill-rule="evenodd"
            />
            <path
                d="M16.5857 469.796H20.761M14.8411 434.66H54.9679L49.734 441.576V463.073C49.734 472.708 41.9229 480.519 32.2875 480.519C22.6521 480.519 14.8411 472.708 14.8411 463.073V434.66ZM14.8411 441.722H20.761H14.8411ZM14.8411 454.877H20.761H14.8411Z"
                stroke="black"
                stroke-width="1.32"
            />
            <path
                d="M108.853 475.932H76.7511V480.518H108.853V475.932Z"
                fill="#171822"
            />
            <path
                d="M106.56 472.493H79.0439V475.932H106.56V472.493Z"
                fill="#171822"
                opacity="0.6"
            />
            <path
                d="M103.12 469.054H83.6299V472.493H103.12V469.054Z"
                fill="#171822"
                opacity="0.4"
            />
            <path
                clip-rule="evenodd"
                d="M93.375 459.231V469.054V459.231ZM93.375 465.775C96.8575 465.775 99.6806 463.072 99.6806 459.737C99.6806 457.514 97.5788 453.03 93.375 446.285C89.1712 453.03 87.0693 457.514 87.0693 459.737C87.0693 463.072 89.8925 465.775 93.375 465.775Z"
                fill-rule="evenodd"
                stroke="#FF586B"
                stroke-width="1.32"
            />
            <path
                d="M538.253 401.065L581.087 429.994L551.015 473.265"
                stroke="#0E7F8D"
                stroke-width="3.564"
            />
            <path
                d="M533.094 402.096L575.927 431.026L545.856 474.297"
                stroke="#0E7F8D"
                stroke-width="1.188"
            />
            <path
                clip-rule="evenodd"
                d="M533.61 471.745H562.501C567.63 471.745 571.787 475.903 571.787 481.032H524.323C524.323 475.903 528.481 471.745 533.61 471.745Z"
                fill="#00C49A"
                fill-rule="evenodd"
            />
            <path
                d="M538.253 406.74C541.387 406.74 543.928 404.199 543.928 401.064C543.928 397.93 541.387 395.389 538.253 395.389C535.119 395.389 532.578 397.93 532.578 401.064C532.578 404.199 535.119 406.74 538.253 406.74Z"
                fill="#0E7F8D"
            />
            <path
                clip-rule="evenodd"
                d="M539.513 386.754C541.63 388.66 541.801 391.922 539.895 394.04L532.99 401.708L525.322 394.803L532.227 387.135C534.133 385.018 537.395 384.847 539.513 386.754Z"
                fill="#00C49A"
                fill-rule="evenodd"
            />
            <path
                clip-rule="evenodd"
                d="M531.227 395.955C537.58 401.675 538.093 411.461 532.373 417.814L528.23 422.415L505.226 401.702L509.369 397.101C515.089 390.748 524.875 390.236 531.227 395.955Z"
                fill="#00C49A"
                fill-rule="evenodd"
            />
            <path
                d="M497.578 701.13V487.398H541.423V701.13"
                stroke="black"
                stroke-linecap="round"
                stroke-width="2.64"
            />
            <path
                d="M52.7427 701.13V487.398H96.5878V701.13"
                stroke="black"
                stroke-linecap="round"
                stroke-width="2.64"
            />
            <path
                clip-rule="evenodd"
                d="M585.789 480.519H7.96191V494.277H585.789V480.519Z"
                fill="#70C8D3"
                fill-rule="evenodd"
            />
            <path
                clip-rule="evenodd"
                d="M97.3876 480.519H7.96196V494.277H97.3876V480.519Z"
                fill="#1B9AAA"
                fill-rule="evenodd"
            />
            <path
                clip-rule="evenodd"
                d="M586.095 661.888H548.43V681.86C548.43 692.261 556.861 700.693 567.262 700.693C577.664 700.693 586.095 692.261 586.095 681.86V661.888Z"
                fill="white"
                fill-rule="evenodd"
                stroke="#00C49A"
                stroke-width="1.32"
            />
            <path
                d="M567.263 662.91C556.862 662.91 548.43 662.224 548.43 661.378C548.43 660.532 556.862 659.846 567.263 659.846C577.664 659.846 586.095 660.532 586.095 661.378C586.095 662.224 577.664 662.91 567.263 662.91Z"
                fill="#00C49A"
            />
            <path
                clip-rule="evenodd"
                d="M593.007 513.997C595.746 520.848 593.258 532.272 585.541 548.268C573.966 572.263 583.896 578.474 567.099 599.971C565.86 580.981 564.162 565.116 562.006 552.375C559.851 539.635 570.184 526.842 593.007 513.997ZM625.742 570.08C622.138 581.931 613.847 589.876 600.869 593.914C581.401 599.971 572.189 614.602 572.189 619.862C569.118 603.361 575.222 591.99 590.501 585.748C605.78 579.505 617.527 574.283 625.742 570.08ZM516.177 544.09C519.883 558.342 525.4 568.208 532.73 573.689C543.724 581.911 552.858 588.1 550.599 603.816C546.144 596.325 539.647 591.071 531.109 588.053C518.302 583.526 508.648 572.297 508.648 563.035C508.648 556.86 511.158 550.545 516.177 544.09Z"
                fill="#00C49A"
                fill-rule="evenodd"
            />
            <path
                d="M593.007 514.437C574.826 531.04 566.245 545.211 567.263 556.949C568.79 574.556 571.385 590.493 564.997 605.167C558.61 619.84 563.941 635.033 567.263 643.723C569.477 649.516 569.986 655.571 568.79 661.888"
                stroke="#00C49A"
                stroke-width="1.584"
            />
            <path
                d="M625.742 570.08C621.326 577.296 611.631 584.275 596.658 591.016C574.198 601.128 570.468 608.99 572.333 624.832C574.198 640.675 575.632 647.466 570.399 661.378M564.659 661.378C566.355 654.495 564.338 648.255 558.61 642.659C550.017 634.265 554.497 615.729 550.866 604.863C547.235 593.998 546.286 589.981 529.19 580.03C517.792 573.397 513.247 562.138 515.555 546.254"
                stroke="#00C49A"
                stroke-width="1.584"
            />
            <path
                clip-rule="evenodd"
                d="M213.118 675.328C204.871 676.999 199.775 677.473 197.83 676.751H188.072C185.412 681.006 181.982 685.156 177.781 689.199C171.479 695.265 166.403 698.992 168.392 709.972C183.72 711.437 193.796 710.434 198.62 706.964C199.687 701.709 200.221 699.082 200.221 699.082L204.555 700.348L213.118 695.521V675.328Z"
                fill="black"
                fill-rule="evenodd"
            />
            <mask
                id="mask2"
                height="306"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                width="128"
                x="180"
                y="372"
            >
              <path
                  d="M194.255 372.224C185.109 398.038 182.061 424.741 185.109 452.335C188.158 479.928 186.488 554.937 180.1 677.36C198.174 677.2 208.274 676.997 210.399 676.751C212.525 676.505 214.917 675.458 217.577 673.611L232.077 523.443L248.543 447.991L240.302 675.734C251.388 677.441 259.58 678.108 264.879 677.734C270.178 677.359 276.285 675.725 283.2 672.831L278.677 662.503C284.331 584.736 288.269 538.383 290.491 523.443C293.824 501.034 309.168 452.644 307.176 414.041L302.145 374.65L194.255 372.224Z"
                  fill="white"
              />
            </mask>
            <g mask="url(#mask2)">
              <path
                  d="M194.255 372.224C185.109 398.038 182.061 424.741 185.109 452.335C188.158 479.928 186.488 554.937 180.1 677.36C198.174 677.2 208.274 676.997 210.399 676.751C212.525 676.505 214.917 675.458 217.577 673.611L232.077 523.443L248.543 447.991L240.302 675.734C251.388 677.441 259.58 678.108 264.879 677.734C270.178 677.359 276.285 675.725 283.2 672.831L278.677 662.503C284.331 584.736 288.269 538.383 290.491 523.443C293.824 501.034 309.168 452.644 307.176 414.041L302.145 374.65L194.255 372.224Z"
                  fill="#171822"
              />
              <path
                  clip-rule="evenodd"
                  d="M189.24 376.067C232.05 399.174 272.145 402.792 309.528 386.921V376.067C276.789 386.536 241.103 384.327 202.469 369.439L189.24 376.067Z"
                  fill="#0D0A0A"
                  fill-rule="evenodd"
              />
              <path
                  clip-rule="evenodd"
                  d="M231.578 376.857L229.699 393.686L232.608 394.228L234.679 376.857H231.578Z"
                  fill="#171822"
                  fill-rule="evenodd"
              />
              <path
                  clip-rule="evenodd"
                  d="M288.033 381.199L286.154 396.614L289.064 397.11L291.135 381.199H288.033Z"
                  fill="#171822"
                  fill-rule="evenodd"
              />
            </g>
            <path
                clip-rule="evenodd"
                d="M242.148 675.9V693.404L264.304 695.476V690.453L273.317 697.621C293.884 700.387 305.37 701.349 307.776 700.508C311.384 699.245 312.194 691.604 305.184 689.532C298.174 687.46 285.168 681.459 282.186 672.831C275.722 675.653 269.637 677.212 263.934 677.507C258.23 677.803 250.968 677.267 242.148 675.9Z"
                fill="black"
                fill-rule="evenodd"
            />
            <mask
                id="mask3"
                height="201"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                width="171"
                x="152"
                y="230"
            >
              <path
                  d="M230.527 230.325L259.57 231.02L265.011 232.017C279.782 237.619 292.215 242.72 302.308 247.322C317.448 254.225 318.659 315.377 321.826 348.155C323.938 370.008 318.977 395.307 306.946 424.053L298.111 379.944C276.735 386.06 250.23 384.707 218.595 375.884C217.471 381.831 212.172 396.024 202.699 418.461L177.276 430.773C162.596 389.616 154.514 365.523 153.031 358.494C150.806 347.95 175.247 260.224 177.276 252.174C179.306 244.123 203.506 240.778 209.255 238.361C213.087 236.749 218.595 234.634 225.78 232.017L230.527 230.325ZM192.405 331.827C188.281 342.062 186.22 347.926 186.22 349.418C186.22 350.91 187.272 357.361 189.375 368.77L192.405 331.827Z"
                  fill="white"
              />
            </mask>
            <g mask="url(#mask3)">
              <path
                  d="M230.527 230.325L259.57 231.02L265.011 232.017C279.782 237.619 292.215 242.72 302.308 247.322C317.448 254.225 318.659 315.377 321.826 348.155C323.938 370.008 318.977 395.307 306.946 424.053L298.111 379.944C276.735 386.06 250.23 384.707 218.595 375.884C217.471 381.831 212.172 396.024 202.699 418.461L177.276 430.773C162.596 389.616 154.514 365.523 153.031 358.494C150.806 347.95 175.247 260.224 177.276 252.174C179.306 244.123 203.506 240.778 209.255 238.361C213.087 236.749 218.595 234.634 225.78 232.017L230.527 230.325ZM192.405 331.827C188.281 342.062 186.22 347.926 186.22 349.418C186.22 350.91 187.272 357.361 189.375 368.77L192.405 331.827Z"
                  fill="white"
              />
              <path
                  clip-rule="evenodd"
                  d="M305.185 254.281L298.29 312.175L298.111 379.944L300.387 391.247V312.175L305.185 254.281Z"
                  fill="#E7EEFF"
                  fill-rule="evenodd"
              />
              <path
                  clip-rule="evenodd"
                  d="M228.632 228.099C226.726 232.337 225.46 237.873 224.835 244.705C223.897 254.953 230.685 319.125 228.632 335.336C227.263 346.143 223.777 360.165 218.174 377.401C265.497 385.395 290.731 388.314 293.876 386.158C297.02 384.001 298.254 381.904 297.58 379.864C279.933 339.596 271.11 310.545 271.11 292.712C271.11 265.962 262.524 245.686 259.891 238.657C258.135 233.971 259.493 229.949 263.966 226.593L230.506 225.385L228.632 228.099Z"
                  fill="#CCE6F4"
                  fill-rule="evenodd"
              />
              <path
                  clip-rule="evenodd"
                  d="M240.318 259.796L238.515 355.075L251.483 371.176L262.574 355.075L249.384 259.796H240.318Z"
                  fill="#FF4848"
                  fill-rule="evenodd"
              />
              <path
                  clip-rule="evenodd"
                  d="M238.533 254.281C242.004 254.281 246.017 254.413 250.573 254.676L252.251 256.935C248.958 260.893 246.45 262.872 244.727 262.872C243.004 262.872 240.466 260.893 237.113 256.935L238.533 254.281Z"
                  fill="#FF4848"
                  fill-rule="evenodd"
              />
              <path
                  clip-rule="evenodd"
                  d="M230.426 228.099L227.763 230.325C225.391 234.823 224.139 239.221 224.005 243.519C223.657 254.696 223.296 262.166 222.92 265.928L244.137 254.953C235.167 251.786 230.639 247.068 230.554 240.798C230.468 234.528 230.426 230.295 230.426 228.099Z"
                  fill="#7FBCF4"
                  fill-rule="evenodd"
              />
              <path
                  clip-rule="evenodd"
                  d="M259.656 228.003L261.757 229.728L266.598 243.533L268.178 264.646L244.387 254.953C254.073 251.821 259.083 247.103 259.415 240.798C259.747 234.494 259.827 230.228 259.656 228.003Z"
                  fill="#7FBCF4"
                  fill-rule="evenodd"
              />
              <path
                  clip-rule="evenodd"
                  d="M227.265 231.052L207.563 244.188L216.593 247.014L203.076 255.505L226.78 321.758C227.689 324.519 228.144 327.725 228.144 331.376C228.144 335.028 227.689 340.227 226.78 346.974C228.206 339.76 229.022 334.561 229.228 331.376C229.788 322.715 228.797 315.55 228.632 312.175C227.66 292.323 225.767 274.3 225.671 262.872C225.538 246.923 226.069 236.316 227.265 231.052ZM288.321 254.953L272.99 247.014L283.926 244.154L262.055 231.052C266.778 254.303 269.809 275.888 271.148 295.809C271.577 304.273 272.191 310.415 272.99 314.234C273.782 318.017 275.554 323.279 278.307 330.02L288.321 254.953Z"
                  fill="#1B9AAA"
                  fill-rule="evenodd"
              />
              <path
                  d="M204.452 297.214L192.362 332.538L189.313 349.236L189.24 366.149L202.699 418.461"
                  stroke="#E7EEFF"
              />
            </g>
            <mask
                id="mask4"
                height="38"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                width="54"
                x="217"
                y="150"
            >
              <path
                  d="M219.564 184.974C217.584 172.775 217.584 166.292 219.564 165.527C217.828 162.395 217.494 159.5 218.562 156.842C220.163 152.855 224.251 148.979 255.991 151.916C264.99 153.341 269.782 157.742 270.365 165.117C270.949 172.493 270.541 179.898 269.141 187.331H219.564V184.974Z"
                  fill="white"
              />
            </mask>
            <g mask="url(#mask4)">
              <path
                  d="M219.564 184.974C217.584 172.775 217.584 166.292 219.564 165.527C217.828 162.395 217.494 159.5 218.562 156.842C220.163 152.855 224.251 148.979 255.991 151.916C264.99 153.341 269.782 157.742 270.365 165.117C270.949 172.493 270.541 179.898 269.141 187.331H219.564V184.974Z"
                  fill="#2C1919"
              />
              <path
                  clip-rule="evenodd"
                  d="M267.05 187.335L219.563 186.469C216.992 172.479 216.992 165.498 219.563 165.527C222.579 170.081 230.589 171.599 243.594 170.081C263.102 167.804 269.557 170.037 267.863 184.974C266.734 194.933 266.463 195.72 267.05 187.335Z"
                  fill="#160F0F"
                  fill-rule="evenodd"
              />
            </g>
            <path
                clip-rule="evenodd"
                d="M221.276 186.113C219.482 184.595 218.036 184.595 216.94 186.113C215.296 188.389 219.924 197.484 221.73 197.484C222.934 197.484 222.783 193.694 221.276 186.113Z"
                fill="#FF927A"
                fill-rule="evenodd"
            />
            <path
                clip-rule="evenodd"
                d="M267.426 186.113C269.22 184.595 270.665 184.595 271.761 186.113C273.406 188.389 268.777 197.484 266.971 197.484C265.767 197.484 265.919 193.694 267.426 186.113Z"
                fill="#FF927A"
                fill-rule="evenodd"
            />
            <mask
                id="mask5"
                height="85"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                width="47"
                x="221"
                y="170"
            >
              <path
                  d="M230.818 223.198C224.457 220.033 221.276 206.434 221.276 182.4V170.081C224.546 172.469 228.349 173.664 232.685 173.664C237.111 173.664 263.906 169.33 266.169 174.685C268.432 180.04 270.084 214.126 259.734 222.88V243.112C258.184 250.325 253.402 254.247 245.388 254.88C233.366 255.829 231.827 247.251 230.818 243.112C230.145 240.353 230.145 233.715 230.818 223.198Z"
                  fill="white"
              />
            </mask>
            <g mask="url(#mask5)">
              <path
                  d="M230.818 223.198C224.457 220.033 221.276 206.434 221.276 182.4V170.081C224.546 172.469 228.349 173.664 232.685 173.664C237.111 173.664 263.906 169.33 266.169 174.685C268.432 180.04 270.084 214.126 259.734 222.88V243.112C258.184 250.325 253.402 254.247 245.388 254.88C233.366 255.829 231.827 247.251 230.818 243.112C230.145 240.353 230.145 233.715 230.818 223.198Z"
                  fill="#FF927A"
              />
              <path
                  d="M241.524 188.731V202.379C242.399 203.292 243.326 203.749 244.304 203.749C245.282 203.749 246.227 203.292 247.14 202.379"
                  stroke="black"
              />
              <path
                  d="M251.608 193.42C252.414 193.42 253.066 192.493 253.066 191.348C253.066 190.203 252.414 189.276 251.608 189.276C250.803 189.276 250.15 190.203 250.15 191.348C250.15 192.493 250.803 193.42 251.608 193.42Z"
                  fill="black"
              />
              <path
                  d="M234.111 193.42C234.916 193.42 235.569 192.493 235.569 191.348C235.569 190.203 234.916 189.276 234.111 189.276C233.305 189.276 232.652 190.203 232.652 191.348C232.652 192.493 233.305 193.42 234.111 193.42Z"
                  fill="black"
              />
              <path
                  d="M229.858 184.508C232.84 183.337 235.716 183.337 238.485 184.508"
                  stroke="black"
              />
              <path
                  d="M255.983 184.508C253.001 183.337 250.126 183.337 247.356 184.508"
                  stroke="black"
              />
              <path
                  clip-rule="evenodd"
                  d="M229.592 222.097C239.498 230.175 249.953 230.175 260.959 222.097L260.249 227.648C251.693 235.836 240.865 239.93 227.763 239.93L229.592 222.097Z"
                  fill="#E1806A"
                  fill-rule="evenodd"
              />
            </g>
            <mask
                id="mask6"
                height="36"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                width="47"
                x="221"
                y="194"
            >
              <path
                  d="M221.73 194.722C223.834 204.97 226.943 209.668 231.056 208.814C237.226 207.534 245.092 204.019 253.795 208.251C262.497 212.483 264.264 212.296 267.769 194.722C267.55 205.107 266.016 213.057 263.167 218.572C258.893 226.843 247.846 232.712 235.044 227.735C226.51 224.418 222.072 213.413 221.73 194.722Z"
                  fill="white"
              />
            </mask>
            <g mask="url(#mask6)">
              <path
                  d="M221.73 194.722C223.834 204.97 226.943 209.668 231.056 208.814C237.226 207.534 245.092 204.019 253.795 208.251C262.497 212.483 264.264 212.296 267.769 194.722C267.55 205.107 266.016 213.057 263.167 218.572C258.893 226.843 247.846 232.712 235.044 227.735C226.51 224.418 222.072 213.413 221.73 194.722Z"
                  fill="#2C1919"
              />
              <path
                  clip-rule="evenodd"
                  d="M221.73 193.42C224.59 216.685 232.711 227.81 246.094 226.794C254.184 226.18 260.489 222.75 265.011 216.503C262.873 221.178 260.347 224.693 257.434 227.047C253.065 230.579 237.184 236.245 229.858 227.705C224.974 222.011 222.265 214.554 221.73 205.333L221.276 194.721L221.73 193.42Z"
                  fill="#160F0F"
                  fill-rule="evenodd"
              />
            </g>
            <path
                clip-rule="evenodd"
                d="M238.607 212.563H251.23C249.249 216.388 246.981 218.208 244.425 218.023C241.869 217.838 239.93 216.018 238.607 212.563Z"
                fill="#EEECEA"
                fill-rule="evenodd"
            />
            <path
                d="M233.964 197.313C237.321 197.313 240.043 194.591 240.043 191.233C240.043 187.875 237.321 185.153 233.964 185.153C230.606 185.153 227.884 187.875 227.884 191.233C227.884 194.591 230.606 197.313 233.964 197.313Z"
                stroke="black"
            />
            <path
                d="M251.335 197.313C254.692 197.313 257.414 194.591 257.414 191.233C257.414 187.875 254.692 185.153 251.335 185.153C247.977 185.153 245.255 187.875 245.255 191.233C245.255 194.591 247.977 197.313 251.335 197.313Z"
                stroke="black"
            />
            <path
                d="M257.415 190.808L268.178 184.974M240.044 190.808H245.255H240.044Z"
                stroke="black"
            />
            <path
                d="M228.224 190.808L220.213 184.069"
                stroke="black"
            />
            <path
                clip-rule="evenodd"
                d="M180.1 429.178L183.965 436.857L202.468 418.564L180.1 429.178Z"
                fill="#FF927A"
                fill-rule="evenodd"
            />
            <path
                d="M252.363 390.159H256.873M264.634 386.158H256.708C254.391 386.158 252.512 388.037 252.512 390.354C252.512 390.403 252.513 390.452 252.514 390.501C252.598 392.903 254.607 394.787 257.01 394.718L264.634 394.498V386.158Z"
                stroke="#707070"
                stroke-width="2"
            />
            <path
                clip-rule="evenodd"
                d="M316.974 293.316C302.489 317.654 296.084 333.829 297.758 341.842C299.388 349.643 310.881 350.901 322.121 350.238L316.974 293.316Z"
                fill="#E7EEFF"
                fill-rule="evenodd"
            />
            <path
                clip-rule="evenodd"
                d="M408.807 689.767C408.547 697.988 409.065 703.034 410.362 704.905C412.308 707.71 419.081 706.69 425.013 700.515C430.945 694.341 432.741 685.128 427.954 674.692C426.57 671.09 425.864 666.514 425.837 660.963C425.81 655.412 422.009 650.546 414.436 646.365L408.807 689.767ZM376.766 658.804C381.461 665.218 383.525 669.443 382.958 671.479C382.107 674.534 377.601 684.103 377.601 686.079C377.601 687.397 377.601 690.592 377.601 695.664H375.776V689.624C374.054 691.382 373.368 695.269 373.718 701.284C374.243 710.307 354.967 712.308 350.733 710.272C346.499 708.236 347.94 700.771 353.565 694.83C357.315 690.87 365.049 678.861 376.766 658.804Z"
                fill="black"
                fill-rule="evenodd"
            />
            <path
                clip-rule="evenodd"
                d="M343.633 527.685C347.848 547.736 350.423 561.884 351.356 570.128C352.755 582.493 355.419 619.828 360.16 641.15C364.901 662.472 359.783 678.874 353.565 694.83C359.295 699.17 365.082 698.979 370.924 694.257C376.793 681.884 379.655 673.851 379.509 670.157C379.291 664.615 376.409 654.728 376.409 644.466C376.409 634.205 380.022 606.706 375.621 590.325C372.688 579.405 368.005 557.681 361.574 525.152L343.633 527.685Z"
                fill="#F99B86"
                fill-rule="evenodd"
            />
            <path
                clip-rule="evenodd"
                d="M388.297 525.283L378.713 552.852C391.855 563.715 399.239 576.863 400.866 592.294C403.307 615.44 407.568 641.244 414.614 646.749C427.649 670.309 429.374 684.703 419.789 689.931C416.823 691.245 413.338 691.573 409.333 690.916C404.166 676.612 401.527 668.014 401.417 665.123C401.251 660.785 399.558 654.279 392.84 636.657C386.122 619.034 347.347 571.542 347.347 558.467C347.347 549.751 348.005 539.099 349.322 526.513L388.297 525.283Z"
                fill="#FFAE9C"
                fill-rule="evenodd"
            />
            <mask
                id="mask7"
                height="170"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                width="99"
                x="323"
                y="359"
            >
              <path
                  d="M419.359 403.764C423.012 422.219 420.953 442.066 413.183 463.304C401.526 495.161 400.715 503.622 399.723 528.532L334.784 528.434C322.307 474.015 319.907 432.458 327.584 403.764C335.26 375.07 337.66 360.332 334.784 359.549L405.193 363.19L419.359 403.764Z"
                  fill="white"
              />
            </mask>
            <g mask="url(#mask7)">
              <path
                  d="M419.359 403.764C423.012 422.219 420.953 442.066 413.183 463.304C401.526 495.161 400.715 503.622 399.723 528.532L334.784 528.434C322.307 474.015 319.907 432.458 327.584 403.764C335.26 375.07 337.66 360.332 334.784 359.549L405.193 363.19L419.359 403.764Z"
                  fill="#1B9AAA"
              />
              <path
                  clip-rule="evenodd"
                  d="M335.695 365.994C346.976 371.484 357.159 388.215 366.243 416.187C379.868 458.144 337.083 508.145 343.633 528.916C347.655 541.671 367.682 543.457 403.715 534.272C402.171 516.333 403.795 501.705 408.589 490.387C415.778 473.409 426.445 436.758 421.111 412.401C417.556 396.163 415.781 380.009 415.786 363.94L382.849 367.939L335.695 365.994Z"
                  fill="#198997"
                  fill-rule="evenodd"
              />
            </g>
            <mask
                id="mask8"
                height="168"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                width="135"
                x="299"
                y="250"
            >
              <path
                  d="M368.955 250.796L383.364 252.498C407.812 259.094 421.63 263.281 424.818 265.06C429.6 267.728 435.837 274.626 432.379 289.579C429.224 303.223 422.065 327.23 412.922 343.142L412.924 343.15L412.718 343.494C411.907 344.891 411.079 346.225 410.238 347.481C419.226 377.406 424.586 400.751 426.32 417.516L386.252 417.445L389.346 369.336C370.364 374.51 353.515 374.11 338.801 368.133C336.213 396.199 334.919 410.231 334.919 410.231L321.098 412.176L331.041 348.901C328.287 349.033 325.71 349.091 323.312 349.074L322.725 349.076C322.365 349.076 322.013 349.067 321.668 349.049C304.983 348.677 297.552 344.431 299.374 336.31C302.575 322.045 327.567 270.382 332.93 263.399C338.294 256.416 345.361 250.796 368.955 250.796Z"
                  fill="white"
              />
            </mask>
            <g mask="url(#mask8)">
              <path
                  d="M368.955 250.796L383.364 252.498C407.812 259.094 421.63 263.281 424.818 265.06C429.6 267.728 435.837 274.626 432.379 289.579C429.224 303.223 422.065 327.23 412.922 343.142L412.924 343.15L412.718 343.494C411.907 344.891 411.079 346.225 410.238 347.481C419.226 377.406 424.586 400.751 426.32 417.516L386.252 417.445L389.346 369.336C370.364 374.51 353.515 374.11 338.801 368.133C336.213 396.199 334.919 410.231 334.919 410.231L321.098 412.176L331.041 348.901C328.287 349.033 325.71 349.091 323.312 349.074L322.725 349.076C322.365 349.076 322.013 349.067 321.668 349.049C304.983 348.677 297.552 344.431 299.374 336.31C302.575 322.045 327.567 270.382 332.93 263.399C338.294 256.416 345.361 250.796 368.955 250.796Z"
                  fill="white"
              />
              <path
                  clip-rule="evenodd"
                  d="M338.488 348.238C339.277 357.702 339.382 364.334 338.801 368.133C342.484 373.327 350.638 375.847 363.261 375.691C375.884 375.536 384.579 373.417 389.346 369.336L388.469 362.377C377.949 361.673 369.086 360.322 361.88 358.324C354.673 356.326 346.876 352.964 338.488 348.238Z"
                  fill="#EFA12C"
                  fill-rule="evenodd"
              />
              <path
                  clip-rule="evenodd"
                  d="M376.926 249.171C368.852 250.458 363.526 253.397 360.949 257.988C357.085 264.874 333.205 299.126 332.521 308.418C332.347 310.777 333.181 316.941 335.021 326.907C337.591 327.521 339.619 327.854 341.106 327.906C343.675 327.997 347.827 327.94 353.561 327.734V327.827C361.351 327.166 366.633 326.548 369.409 325.972C374.979 324.817 378.456 322.845 380.283 321.929C380.669 321.735 381.315 321.634 382.221 321.626C383.18 321.486 384.378 321.783 385.816 322.519C386.128 316.649 386.519 312.796 386.99 310.959C390.489 297.307 397.013 265.27 392.296 256.364C389.151 250.427 384.028 248.029 376.926 249.171Z"
                  fill="#EFA12C"
                  fill-rule="evenodd"
              />
              <path
                  clip-rule="evenodd"
                  d="M387.306 362.293C396.06 361.416 403.704 356.478 410.238 347.48C414.102 357.961 416.958 367.367 418.805 375.698L387.306 362.293Z"
                  fill="#E7EEFF"
                  fill-rule="evenodd"
              />
              <path
                  clip-rule="evenodd"
                  d="M390.08 326.137L403.874 288.942L388.578 324.686L390.08 326.137Z"
                  fill="#E7EEFF"
                  fill-rule="evenodd"
              />
              <path
                  clip-rule="evenodd"
                  d="M338.984 355.021L338.488 348.239L330.176 349.076L338.984 355.021Z"
                  fill="#E7EEFF"
                  fill-rule="evenodd"
              />
              <path
                  clip-rule="evenodd"
                  d="M320.886 321.514L335.695 284.089C329.401 300.463 326.255 309.697 326.255 311.79C326.255 313.883 327.562 318.761 330.175 326.423L320.886 321.514Z"
                  fill="#E7EEFF"
                  fill-rule="evenodd"
              />
            </g>
            <path
                d="M377.848 188.763C385.014 188.763 390.823 182.9 390.823 175.667C390.823 168.434 385.014 162.571 377.848 162.571C370.682 162.571 364.872 168.434 364.872 175.667C364.872 182.9 370.682 188.763 377.848 188.763Z"
                fill="#7D1C1C"
            />
            <path
                clip-rule="evenodd"
                d="M359.037 226.752C353.934 214.092 352.433 201.975 354.535 190.402C357.687 173.041 381.033 173.032 391.878 178.8C402.723 184.567 407.151 202.901 396.072 226.752C388.687 242.653 376.342 242.653 359.037 226.752Z"
                fill="#7D1C1C"
                fill-rule="evenodd"
            />
            <path
                d="M379.899 162.866C388.73 163.88 392.371 169.035 390.823 178.331C402.031 182.03 405.491 192.596 401.204 210.028"
                stroke="#7D1C1C"
            />
            <path
                d="M364.872 177.588C372.669 173.938 381.319 174.185 390.823 178.331"
                stroke="white"
            />
            <mask
                id="mask9"
                height="78"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                width="50"
                x="353"
                y="194"
            >
              <path
                  d="M401.893 211.085C401.264 209.576 399.792 209.292 397.478 210.233L397.325 204.82C390.352 204.508 384.481 204.508 379.482 203.168C374.931 201.947 369.548 199.185 363.333 194.881C361.651 198.232 359.947 200.26 358.219 200.967V209.918C355.741 208.82 354.216 209.209 353.641 211.085C352.78 213.899 356.003 220.888 357.875 221.7C359.053 229.02 361.424 234.98 364.989 239.58C365.825 248.706 365.825 254.71 364.989 257.59C363.736 261.91 362.214 269.309 371.933 271.281C381.651 273.252 389.774 261.059 390.352 257.59C390.737 255.277 390.737 249.274 390.352 239.58C393.062 236.486 395.386 230.526 397.325 221.7C398.042 221.471 398.77 220.897 399.509 219.978C401.132 217.961 402.836 213.349 401.893 211.085Z"
                  fill="white"
              />
            </mask>
            <g mask="url(#mask9)">
              <path
                  d="M401.893 211.085C401.264 209.576 399.792 209.292 397.478 210.233L397.325 204.82C390.352 204.508 384.481 204.508 379.482 203.168C374.931 201.947 369.548 199.185 363.333 194.881C361.651 198.232 359.947 200.26 358.219 200.967V209.918C355.741 208.82 354.216 209.209 353.641 211.085C352.78 213.899 356.003 220.888 357.875 221.7C359.053 229.02 361.424 234.98 364.989 239.58C365.825 248.706 365.825 254.71 364.989 257.59C363.736 261.91 362.214 269.309 371.933 271.281C381.651 273.252 389.774 261.059 390.352 257.59C390.737 255.277 390.737 249.274 390.352 239.58C393.062 236.486 395.386 230.526 397.325 221.7C398.042 221.471 398.77 220.897 399.509 219.978C401.132 217.961 402.836 213.349 401.893 211.085Z"
                  fill="#FFAE9C"
              />
              <path
                  d="M375.788 213.75V227.092C376.494 227.83 377.24 228.199 378.027 228.199C378.814 228.199 379.575 227.83 380.31 227.092"
                  stroke="black"
              />
              <path
                  d="M383.907 216.769C384.555 216.769 385.08 216.02 385.08 215.095C385.08 214.17 384.555 213.42 383.907 213.42C383.259 213.42 382.733 214.17 382.733 215.095C382.733 216.02 383.259 216.769 383.907 216.769Z"
                  fill="black"
              />
              <path
                  d="M369.058 216.769C369.706 216.769 370.232 216.02 370.232 215.095C370.232 214.17 369.706 213.42 369.058 213.42C368.41 213.42 367.885 214.17 367.885 215.095C367.885 216.02 368.41 216.769 369.058 216.769Z"
                  fill="black"
              />
              <path
                  d="M365.635 210.337C368.035 209.39 370.35 209.39 372.58 210.337"
                  stroke="black"
              />
              <path
                  d="M387.428 210.337C385.028 209.39 382.713 209.39 380.483 210.337"
                  stroke="black"
              />
              <path
                  clip-rule="evenodd"
                  d="M372.678 233.012C374.978 233.421 376.726 233.626 377.923 233.626C379.119 233.626 380.933 233.421 383.364 233.012C381.687 235.704 379.767 236.985 377.603 236.855C375.44 236.725 373.798 235.444 372.678 233.012Z"
                  fill="#EEECEA"
                  fill-rule="evenodd"
              />
              <path
                  clip-rule="evenodd"
                  d="M364.004 238.553C373.173 248.204 382.3 248.204 391.383 238.553L390.952 243.564C387.93 247.351 384.128 250.203 379.547 252.119C374.965 254.036 369.784 255.451 364.004 256.364V238.553Z"
                  fill="#F99B86"
                  fill-rule="evenodd"
              />
            </g>
            <path
                d="M356.476 221.889C357.319 221.889 358.003 221.199 358.003 220.348C358.003 219.497 357.319 218.807 356.476 218.807C355.633 218.807 354.949 219.497 354.949 220.348C354.949 221.199 355.633 221.889 356.476 221.889Z"
                fill="#1B9AAA"
            />
            <path
                d="M398.456 221.889C399.299 221.889 399.983 221.199 399.983 220.348C399.983 219.497 399.299 218.807 398.456 218.807C397.612 218.807 396.929 219.497 396.929 220.348C396.929 221.199 397.612 221.889 398.456 221.889Z"
                fill="#1B9AAA"
            />
            <path
                d="M363.27 194.054C372.392 203.976 383.744 207.564 397.325 204.82"
                stroke="#7D1C1C"
            />
            <path
                clip-rule="evenodd"
                d="M330.176 326.423C329.377 318.18 328.611 313.468 327.877 312.287C326.775 310.514 322.847 307.236 321.935 304.787C319.486 305.27 318.939 306.699 320.293 309.073C322.323 312.634 323.886 314.685 322.911 315.157C319.638 311.517 317.464 309.592 316.389 309.382C314.776 309.068 307.383 308.342 306.91 309.382C306.596 310.076 307.038 310.879 308.237 311.79C307.344 311.876 306.724 312.422 306.377 313.43C305.856 314.941 306.864 316.236 308.728 317.319C307.048 317.176 306.324 317.568 306.559 318.494C306.91 319.884 311.708 321.127 312.207 323.207C316.585 322.278 320.468 322.478 323.857 323.805C324.907 324.217 327.013 325.089 330.176 326.423Z"
                fill="#FFAE9C"
                fill-rule="evenodd"
            />
            <path
                d="M308.236 311.79L314.936 312.986L318.305 315.389"
                stroke="#7D1C1C"
            />
            <path
                d="M306.377 313.59L313.27 315.389L316.431 317.709"
                stroke="#7D1C1C"
            />
            <path
                d="M308.367 317.201C310.392 317.54 312.27 318.64 314.001 320.503"
                stroke="#7D1C1C"
            />
            <path
                clip-rule="evenodd"
                d="M353.561 327.734V344.749L368.562 337.915H385.922L390.081 326.137C386.979 322.808 384.305 321.277 382.059 321.544C378.69 321.945 371.825 325.711 367.062 326.137C363.886 326.421 359.386 326.953 353.561 327.734Z"
                fill="#FFAE9C"
                fill-rule="evenodd"
            />
          </svg>

        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
          class="d-flex align-items-center auth-bg reg-right-side"
          lg="6" xl="5"
      >

        <b-col
            class="px-xl-2 mx-auto right-col"
            cols="12"
            xl="11"
            lg="12"
            md="9"

        >
          <h1 class="txt-black">Hello!</h1>
          <h2 class="txt-black">Welcome back.</h2>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
                class="auth-login-form mt-2"
                @submit.prevent
            >
              <!-- email -->
              <b-form-group
                  label="Email"
                  label-for="login-email"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|emailTest"
                >
                  <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between mt-1">
                  <label for="login-password">Password</label>
<!--                  <b-button class="p-0" size="sm" variant="link" @click="$router.push('forgot-password')">Forgot-->
<!--                    Password?-->
<!--                  </b-button>-->

                </div>
                <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                >
                  <b-input-group
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      class="input-group-merge"
                  >
                    <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        :type="passwordFieldType"
                        class="form-control-merge"
                        name="login-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="password">
              <b-button class="p-0 forgot" size="sm" variant="link" @click="$router.push('forgot-password')">Forgot
                Password?
              </b-button>
              </div>


              <b-overlay
                  :opacity="0.5"
                  :show="loading"
                  rounded="sm"
              >
                <b-button
                    :disabled="loading"
                    block
                    class="bg-black mt-2 py-1"
                    type="submit"
                    variant="dark"
                    @click="validationForm"

                >
<!--                  @click="validationForm"-->
                  Sign in
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>

<!--          <b-button-->
<!--              :disabled="loading"-->
<!--              block-->
<!--              class="bg-black mt-2 py-1"-->
<!--              type="submit"-->
<!--              variant="dark"-->
<!--              @click="getCurrentUser()"-->




<!--          >-->
<!--            &lt;!&ndash;                  @click="validationForm"&ndash;&gt;-->
<!--            get Current user-->
<!--          </b-button>-->



          <p class="mt-2">Don’t have an account?
              <b-button class="pl-0" variant="link" @click="$router.push('/register')">Create account</b-button>
          </p>
        </b-col>


      </b-col>
      <!-- /Login-->

    </b-row>

  </div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationObserver, ValidationProvider} from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import store from '@/store/index'
import {mapActions} from 'vuex'
import {email, required} from '@validations'
import profile from "@/apis/modules/profile";
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,

    // BImg,
    BForm,
    BButton,
    BOverlay,
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      loading: false,
      required
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  methods: {
    ...mapActions(['login', 'logout']),
    async validationForm() {
      if (await this.$refs.loginValidation.validate()) {
        this.loading = true
        try {
          this.payload = {
            email: this.userEmail,
            password: this.password,
          }
          await this.login(this.payload)
        } catch (error) {
          this.convertAndNotifyError(error)
        }
        this.loading = false
      }
    },
    async getCurrentUser(){
      // await profile.getCurrentUser()

        // it gets the cookie called `username`
        const username = this.$cookies.get("XSRF-TOKEN");
        // console.log(username);

    }
  },
}
</script>

<style lang="scss">
.password {
  text-align: right;
}
@import "~@core/scss/vue/pages/page-auth.scss";
</style>
